import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a
              href="/radca/nadcha/co_je_zapal_prinosovych_dutin/"
              className="sel"
            >
              {" "}
              &gt; Čo je zápal prinosových dutín?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_118647259.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Čo je zápal prinosových dutín?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    <b>
                      Akútny zápal prinosových dutín patrí medzi desať
                      najčastejšie vznikajúcich ochorení. Obvykle sa objavuje na
                      jar a jeseň v dôsledku vírusovej{" "}
                      <a href="/slovnik_pojmov/#infekcia" target="_blank">
                        infekcie
                      </a>
                      . Medzi prvé príznaky patrí bolesť v oblasti nosa a čela,
                      čoraz silnejšia nepriechodnosť horných dýchacích ciest a
                      zvýšená telesná teplota. Akútny zápal prinosových dutín si
                      možno pomýliť s prechladnutím, preto treba vedieť, ako ich
                      odlíšiť.{" "}
                    </b>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Prinosové dutiny (priestory vo vnútri tvárovej časti lebky)
                    sa nachádzajú medzi nosovou dutinou a kosťami, podľa ktorých
                    sú nazvané (čelové, čuchové, klinové, čeľustné). Sú pokryté
                    sliznicou, ktorá produkuje hlien. Vytváraný hlien chráni
                    pred bakteriálnou infekciou. Prinosové dutiny plnia okrem
                    iného tieto funkcie: ochranné (chránia pred mechanickými
                    úrazmi aj teplotou), respiračné (zvlhčujú), a taktiež vplyv
                    na zvuk nášho hlasu. Neliečená nádcha,{" "}
                    <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                      prechladnutie
                    </a>
                    &nbsp;a v niektorých prípadoch aj alergie spôsobujú
                    zablokovanie výstupu z prinosových dutín, ktoré sa naplnia
                    nadmerným množstvom hlienov, čím vzniká aj zápal prinosových
                    dutín. Ďalšie faktory prispievajúce k zápalu prinosových
                    dutín sú anatomické podmienky (napr. vychýlenie nosovej
                    priehradky, zväčšenie nosových mušlí, útvary brániace
                    ventilácii prinosových dutín) a prekonané{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekcie
                    </a>
                    &nbsp;horných dýchacích ciest.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                  <img
                    alt="Prečítajte si o príznakoch zápalu prínosových dutín a ako OLYNTH® môže pomôcť s týmto problémom."
                    src="/web/kcfinder/uploads/images/shutterstock_243107530.jpg"
                    style={{
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid rgb(255, 255, 255)",
                      width: "300px",
                      height: "450px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Ako rozpoznať akútny zápal prinosových dutín? Prvé príznaky
                  súvisia s lokalizáciou zápalu. Ak je prítomné silné pnutie pri koreni
                  nosa a bolesť za okom, ide o zápal čeľustných dutín. Ak bolesť
                  vyžaruje do zubov, uší a spánkovo-čelovej oblasti, zápal
                  postihol čeľustné dutiny. Ťažkosti lokalizované nad
                  nadočnicovým oblúkom a na čele signalizujú zápal čelových
                  dutín. Bolesť v oblasti zadnej často lebky znamená zápal
                  klinových dutín. Dôležité je, že pri všetkých typoch zápalu
                  prinosových dutín sa príznaky zintezívňujú ráno, pri vstávaní
                  z postele a pri predklone.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    K typickým príznakom zápalu prinosových dutín patrí: silný,
                    hlienistý až hnisavý výtok z nosa, chronická nádcha, opuch
                    nosovej sliznice, zvýšená telesná teplota (okolo 38 °C),
                    poruchy čuchu a chuti, celkové oslabenie, únava, kašeľ a
                    nepríjemný zápach z úst.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Doba trvania ťažkostí je určujúca pre typ zápalu dutín.
                    Zápal podľa toho delíme na:{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <ul>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span className="s1">
                      <strong>akútny zápal prinosových dutín</strong>, čiže{" "}
                      <a href="/slovnik_pojmov/#infekcia" target="_blank">
                        infekciu
                      </a>
                      , ktorá sa začína náhle a trvá až štyri týždne. Ak je
                      správne liečená, nespôsobuje komplikácie (napr. zápal
                      ucha).{" "}
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span className="s1">
                      recidivujúci akútny zápal prinosových dutín, čiže
                      minimálne štyri epizódy zápalu prinosových dutín počas
                      roka, ktoré trvajú 7 až 10 dní a zároveň nie sú chronickým
                      zápalom prinosových dutín.{" "}
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span className="s1">
                      <strong>subakútny zápal</strong> prinosových dutín
                      trvajúci 4 až <br/>12 týždňov. Po tejto dobe ešte nedochádza k
                      trvalým zmenám a je možná regenerácia sliznice prinosových
                      dutín.{" "}
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span className="s1">
                      <strong>chronický zápal prinosových</strong> dutín, ktorý
                      je dôsledkom nesprávne liečeného akútneho (často
                      recidivujúceho) alebo subakútneho zápalu prinosových
                      dutín. V takom prípade choroba trvá kratšie ako 12
                      týždňov.{" "}
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span className="s1">
                      <strong>zhoršený chronický zápal</strong> prinosových
                      dutín, čiže náhle zhoršenie sa chronického zápalu
                      prinosových dutín. Príznaky sa vtedy zintenzívnia, vystúpi
                      horúčka a bolesť sa stupňuje.{" "}
                    </span>
                  </li>
                </ul>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                  Pomôžu nám správne lieky. Na spriechodnenie nosa a prinosových dutín je vhodné siahnuť po nosovej roztokovej aerodisperzii{" "}
                    <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                    </a>{" "}
                    ,{" "}
                    <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                    </a>{" "}
                    alebo{" "}
                    OLYNTH® PLUS 1 mg/50 mg/ml. Nosové roztokové aerodisperzie (obsahujúce{" "}
                    <a href="/slovnik_pojmov/#xylometazolin">xylometazolín</a>)
                     zužujú cievky nosovej sliznice a sliznice prinosových dutín, čím sa v nich zmenšuje opuch a snižuje množstvo výtoku. Zápalový stav sa tak zmierňuje a hlieny môžu voľne vytekať z prinosových dutín a nosa.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    V mnohých prípadoch sa zápal prinosových dutín rozvinie v
                    dôsledku prekonaného prechladnutia. Aj preto je dôležité
                    predchádzať vírusovým{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekciám
                    </a>
                    &nbsp;a starať sa o správne fungovanie nášho organizmu.
                    Odporúča sa stráviť každý deň po celý rok nejaký čas na
                    čerstvom vzduchu, čím si otužujeme telo. Treba sa preto
                    snažiť o každodennú aktivitu mimo domova. Môžete sa vybrať
                    na prechádzku, previezť sa na bicykli alebo si zabehať. Tzv.
                    slnečnými kúpeľmi (ktoré majú najlepšie účinky, ak svieti
                    slnko) si zvyšujeme odolnosť voči mnohým ochoreniam.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Lepšiu odolnosť organizmu podporuje aj správna strava bohatá
                    na vitamíny a prvky dôležité pre ľudský organizmus. Náš
                    jedálniček musí povinne obsahovať: ovocie a zeleninu
                    (obsahujúce okrem iného vitamíny A, C, betakarotén, ale aj
                    fosfor a draslík), ryby (zdroj zinku), cesnak (s
                    baktericídnymi účinkami), cibuľu (chráni pred infekciami) a
                    bylinky (napr. bylinné čaje z rumančeka, malín alebo čiernej
                    bazy). Tieto potraviny zvyšujú našu odolnosť a pomáhajú
                    predchádzať prechladnutiu.{" "}
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    V jesenno-zimnom období by sme sa mali vyhýbať veľkým
                    zhromaždeniam ľudí. Ak predsa len dostaneme nádchu, mali by
                    sme používať uvoľňujúce lieky, ktoré spriechodnia dýchanie
                    nosom a prinosové dutiny, aby sa hlieny nehromadili.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Choroby ucha, nosa i gardła z chirurgią głowy i szyi,
                        opracowanie zbiorowe, Wrocław, Wydawnictwo Medyczne
                        Urban &amp; Partner, 2012.
                      </span>
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)"}} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)"}} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)"}} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
